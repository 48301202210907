import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';




export default function SellCart(props) {
    let history = useHistory();
   // const [loading, setloading] = useState(true);
   const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;


  const [cart, setCart] = useState([]);
  const [boxes, setBoxes] = useState([]);
  //const [products, setProducts] = useState([]);

  useEffect(() => {
            let ids = [];
            let temp_cart = JSON.parse(localStorage.getItem('sellCart'));
      

            if(temp_cart!=null){
                setCart(temp_cart);

            }
           
           
 
  }, [history]);



  function limitCrossed(id,stock){
    let cart = JSON.parse(localStorage.getItem("sellCart")); 
    if(cart==null)
      return false;

     let total = 0;
    cart.forEach(element => {
        if(element.id===id){
           total +=element.quantity;
        }
    });


    if(total>=stock)
     return true;

     return false;

   };

 
  const deleteItem = (id)=>{
    let temp_cart = cart;

    temp_cart = temp_cart.filter(item => item.product.id !== id);

    localStorage.setItem("sellCart",JSON.stringify(temp_cart));
    setCart([...temp_cart]);
  

}

  

//To calculate Total price
let total = 0;
cart.forEach(element => {
   total +=element.quantity*element.price; 
});






/*
if(loading){
    return(
         <div>
            Loading..
         </div>
    )
   } 
else */


if(cart.length===0){
    return(
        <div>
            Empty Cart
        </div>
    )
}
else{
  return (
    <>
    <div className='d-flex flex-wrap justify-content-center align-items-center'>
              {
                         cart.map((item,index)=>{
                            return(
                                <>
                       <div key={index} className="card m-3 " >
                                        
                                        <div className="card-body d-flex flex-column justify-content-around">
                                            <h5 className="card-title">{item.product.name}</h5>
                                              
                                            <div className='detailContainer d-flex flex-column align-items-center'>
                                               
                                                <div className='d-flex flex-column align-items-center'>
                                                    
                                                    </div>

                                                 
                                                    <span className='fs-5 bold my-3'>Price : <span className='fs-5'>{item.price} Tk</span></span>
                                                    
                                                    
                                                     
                                                        
                                                        <span className='fs-5 bold my-3'>Quantity: {item.quantity} </span>
                                                       
                                                        
                                                    
                                                    <div className='d-flex justify-content-center'>
                                                    <button onClick={(e)=>deleteItem(item.product.id)} className='btn bg-danger m-2'>Delete</button>
                                                    </div>
                                            </div>        
                                       
    
                                     
                                       
                                        </div>
                        </div>

                                 
                                 </>
                            )
                          
                                
                      })

                     


               }

               
               
    </div>

    
     <div>       
              <h5 className='text-black'>Total : {total} Tk</h5>
               
              <Link to={`/admin/checkout/sell`} className='m-3 btn bg-info'>Checkout</Link>

     </div>
     </>
  )
}

}
