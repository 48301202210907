import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
 
import { Link } from 'react-router-dom';

export default function ViewCategory() {

    let history = useHistory();
    
    const [loading, setloading] = useState(true);

    const [categories, setCategories] = useState([]);

    const [errors, setErrors] = useState([]);
   
    
    
    const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
    useEffect(() => {
     
    axios.get('/api/view-category',{
      headers: { 'Allowed-Source': allowedSource }
  })
  .then(res=>{
        
        if(res.data.status===200){
            
            setCategories(res.data.category);
        }
        else{
            setErrors(res.data.messages);
            history.push('/admin/dashboard');
        }
        setloading(false);

    })
    .catch(error=>{
        //console.log(error);
        history.push('/admin/dashboard');
   });  



    }, [history]);


  
   
   if(loading){
    return(
         <div>
            Loading categories..
         </div>
    )
   } 

else{
  return (
    <div>
       
                <table class="table">
  <thead>
    <tr>
      <th scope="col">Serial</th>
      <th scope="col">Slug</th>
      <th scope="col">Name</th>
      <th scope="col">description</th>
      <th scope="col">metaTitle	</th>
      <th scope="col">metaKeywords	</th>
      <th scope="col">metaDescription	</th>
      <th scope="col">Update</th>
    </tr>
  </thead>
  <tbody>
   

        {
           categories.map((category,index)=>{

            return(
                <tr>
                <th scope="row">{index+1}</th>
                <td>{category.slug}</td>
                <td>{category.name}</td>
                <td>{category.description}</td>
                <td>{category.metaTitle}</td>
                <td>{category.metaKeywords}</td>
                <td>{category.metaDescription}</td>
                { category.id!==1 &&
                  <td><Link to={`/admin/update-cat/${category.id}`} className='btn btn-warning'>Update</Link></td>
                }
                
              </tr>
            )

           }) 
        }

  </tbody>
</table>
                
          

    </div>
  )
}



}
