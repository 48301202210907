import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';

export default function Bill() {
  let history = useHistory();
  const [loading, setloading] = useState(true);
  
  const [bills, setBills] = useState([]);

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  useEffect(() => {
     
    axios.get('/api/bills',{
      headers: { 'Allowed-Source': allowedSource }
  })
    .then(res=>{
        
        if(res.data.status===200){
       
            setBills(res.data.bills);
          
        }
        setloading(false);

    })
    .catch(error=>{
        //console.log(error);
      //  history.push('/admin/dashboard');
   });  



    }, [history]);

console.log(bills)

if(loading){
  return(
    <div>Loading..</div>
  )
}
else{
  return (
    //main div
    <div className='container-fluid px-4'> 
      

      {  bills.length >0 &&

        bills.map((bill,index)=>{

            return(
                <>
                <div className='billRow text-start p-3  bg-primary border border-2 rounded my-3' >
                
                      <h5 className=' '>Serial: {bill.id}</h5>
                      <h5 className=''>Company: {bill.name}</h5>
                      <h5 className=' '>Profit :{bill.profit} Tk</h5>
                      <h5 className=' '>Date: {bill.created_at.substring(0, 10)}</h5>
                      <Link to={`/admin/billDetails/${bill.id}`} className='btn btn-info'>Details</Link>
                </div>
                </>
            )
        })

      }
          
  
    </div> //main div
  )
}


}
