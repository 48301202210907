import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
 

export default function UpdateCat() {
   
    let history = useHistory();
    const { id } = useParams();

    const [loading, setloading] = useState(true);
  const [errors, setErrors] = useState([]);

  const [categoryInput1, setCategory] = useState({
    slug:'',
     name:'',
     description:'',

     metaKeywords:'',
     metaTitle:'',
     metaDescription:'',
     errorList:[],
 });

const handleInput = (e) =>{
   e.persist();
   setCategory({...categoryInput1,[e.target.name]:e.target.value});
}
   
const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
    useEffect(() => {
        const data ={
            id:id
        }
     
        axios.post('/api/get-cat-by-id',data,{
          headers: { 'Allowed-Source': allowedSource }
      })
      .then(res=>{
            
            if(res.data.status===200){
                setCategory({...categoryInput1,
                slug:res.data.category.slug,
                name:res.data.category.name,
                description:res.data.category.description,
                metaTitle:res.data.category.metaTitle,
                metaKeywords:res.data.category.metaKeywords,
                metaDescription:res.data.category.metaDescription});
              
            }
            else{
             
            }
            setloading(false);
    
        })
        .catch(error=>{
            
       });  
  
    
        }, [history]);

        const submitCategory = (e) =>{
            e.preventDefault();
            
            const categoryInput = {
              slug:categoryInput1.slug,
              name:categoryInput1.slug,
              description:categoryInput1.slug,
          
              metaKeywords:categoryInput1.slug,
              metaTitle:categoryInput1.slug,
              metaDescription:categoryInput1.slug,
            }

            const data ={
                categoryInput:categoryInput,
                id:id,
            }
         
            axios.post('/api/update-category',data,{
              headers: { 'Allowed-Source': allowedSource }
          }).then(response=>{
              const res = response.data;
         
             if(res.status ===200){
                 
                 document.getElementById('theForm').reset();
                swal("Success",res.message,"success");
              
             }
             else if(res.status ===400){
                swal("Warning","Fill up all the fields","warning")
                 setCategory({...categoryInput1,errorList:res.message});
             }
         
         
            });
         
         
          }
      
      

if(loading){
  return(
    <div>Loading..</div>
  )
}
else{
  return (
    <div>
      
        {/* Form Starts*/}
        <h1>Add category</h1>

<form onSubmit={submitCategory} id='theForm'>
     

 

  <div className="form-group mb-3">
    <label >Name</label>
    <input onChange={handleInput} value={categoryInput1.slug} class="form-control"  type="text" id="slug" name='slug' />
  </div>

   




 

<button type='submit' className='btn btn-primary'>Submit</button>

</form>
{/* Form Ends*/}


    </div>
  )
}
}
