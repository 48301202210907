import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';

import { useParams } from 'react-router-dom';


export default function SelectProduct() {
    let history = useHistory();
    const { buyOrSell } = useParams();
    const [products, setProducts] = useState([]);
    const [errors, setErrors] = useState([]);
    const [loading, setloading] = useState(true);
    const [categories, setCategories] = useState([]);
    const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
    const [selectedCat,setSelectedCat] = useState(-1);

    useEffect(() => {
         
        axios.get('api/get-all-products',{
            headers: { 'Allowed-Source': allowedSource }
        })
        .then(res=>{
            if(res.data.status===200){
              setCategories(res.data.category);
                setProducts(res.data.products);
            }
            else{
                //console.log(res.data.message);
            }
           setloading(false);

        })
        .catch(error=>{

            setErrors(error);
        });
    
      
    }, [history]);

   

    const [search,setSearch] = useState("");
    
    const Search = (e) =>{
        let temp_search=""; 
        temp_search= e.target.value;
    
          setSearch(temp_search);
    
       };

    const selectProduct = (id,name,pic,stock) =>{
        let item = {
         name:name,
         id:id,
         pic:pic,
         stock:stock
        }
        localStorage.setItem("buyItem",JSON.stringify(item));
        history.push("/admin/buy");
       };
    
    
    
    if(loading){
        return(
             <div>
                Loading Products..
             </div>
        )
       }   
    else{
  return (
          <div className='Container d-flex flex-column flex-wrap justify-content-center'>
            <input type="text" placeholder='Search item' className="searchBar w-25 mx-auto m-3 border rounded" name='search' value={search} onChange={(e)=>Search(e)}/>
             
             
            <div>
<label>Category</label>
  <select class="form-select form-control mb-3" name='category_id' aria-label="Default select example"  onChange={(e)=>setSelectedCat(e.target.value)} >
  
  <option value={-1}>All</option>
        {
          categories.map((theCat,index)=>{

            return(
         
                <option key={index} value={theCat.id}>{theCat.name}</option>
       
            )
           
          })
        }

  </select>
  </div>

              <div className='Container d-flex flex-wrap justify-content-center align-items-center'>
                 {
                      products.filter(item =>{
                        let temp_search = search.toLowerCase();
                        let temp_name = item.name.toLowerCase();
                        if(temp_search==="")
                          return true;
                        else if(temp_name.includes(temp_search)){
                          return true;
                        }
                        else
                          return false;
                        
                       }
                      ).filter(item=>{
                         if(selectedCat==-1)
                           return true;
                         if(selectedCat==item.category_id)
                          return true;

                          return false;

                           
                      }).map((product)=>{
                             
                            return(
                                <div className='eachProduct d-flex flex-column align-items-center justify-content-between m-2 text-black border border-2 border-black'>
                                      
                                        <span className='m-2'>Product ID : {product.id}</span>
                                        <span className='m-2 border-bottom border-black rounded'>Product Name : {product.name}</span>
                                      
                                        <span className='m-2'>Price : {product.price} Tk</span>
                                        <span className='m-2'>Stock : {product.stock}</span>
                           
                                        <Link to={`/admin/product/${product.id}/${buyOrSell}`} className='btn btn-warning m-1'>Select</Link>
                                        
                                </div>
                            )
                        })
                            
                        }    

               </div>         
                        
          </div>
  )
    }
}
