import React, { useEffect, useState } from 'react'
 
import loader from '../../images/loading.gif'
 
 
import ReactPixel from 'react-facebook-pixel';



export default function Home(props) {
  

 
   const [loading, setloading] = useState(true);

   useEffect(() => {
    const token = localStorage.getItem('auth_token');
     
    if(token===null){
      
      props.setAdmin(false)
      props.setloggedIn(false)
    }
     
    setloading(false);
  
   },[])

   
if(loading){
  return(
    <div >
      <img  src={loader}   alt="Your GIF"/> 
      
    </div>

  )
}
else{
  return (
    <div className='home-wrapper'>
            

           

           
             
              
    </div>
  )
}
  
}
