import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function ProfitByMonth() {
  let history = useHistory();
  const { date } = useParams();
  const [total,setTotal] = useState(0);


  const [sells,setSells] = useState([]);
  const [loading,setloading] = useState(true);

  const [accessDenied,setAccess] = useState(true);
 
 
  const [expenses,setExpenses] = useState([]);
  
 const [profit,setProfit] = useState(0);
 const [cost,setCost] = useState(0);
 const [sold,setSold] = useState(0);
 const [buyingPrice,setBuy] = useState(0);

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  
  useEffect(() => {
     
   const data = {
    date:date
   }

   axios.post('/api/profitAndCost',data, {
    headers: { 'Allowed-Source': allowedSource }
})
.then(res=>{
    
    if(res.data.status===200){
        setSells(res.data.sells)
        setExpenses(res.data.expenses)
        setTotal(res.data.total);
        setAccess(false);

        let profit = 0;
        let buy = 0;
        let sold = 0;
        res.data.sells.forEach(element => {
              
           profit += parseFloat(element.profit,10,2);
           sold += parseFloat(element.price,10,2)*element.quantity;
           buy += parseFloat(element.cost,10,2)*element.quantity;
              
        });
        setBuy(buy)
        setSold(sold);
        setProfit(profit)

        let expense = 0;
        res.data.expenses.forEach(element => {
              
          expense += parseFloat(element.amount,10,2);
              
        });
        setCost(expense);
       
    }
    else{
       
       // history.push('/');
    }
    setloading(false);

})
.catch(error=>{
  
   // history.push('/admin');
}); 

    setloading(false)

    }, [history]);


    axios.interceptors.response.use(function(response){
      return response;
    },function (error){
     if(error.response.status === 403){//access denied
         swal("Forbidden",error.response.data.message,"warning");
         setAccess(true);
         history.push("/admin/dashbboard")
     }
      
    }
  
    );
   


if(loading){
  return(
    <div>Loading..</div>
  )
}
else if(accessDenied){
  return(
<div className='text-black'>Access Denied</div>
  )

}
else{
  return (
    //main div
    <div className='text-black d-flex flex-column w-75 mx-auto'> 
    <h3 className='mt-4'>NET PROFIT</h3>
                   <div className=' d-flex flex-column my-3 w-100 bg-white'>
                                       
                                        <div className='each-row  d-flex fs-4'>
                                                <span className='border-bottom border-top border-start border-end border-1 border-black w-50'>Name</span>
                                            
                                                <span className='border-bottom border-top border-end border-1 border-black w-50'>Amount</span>
                                              
                                                
                                        </div>
                                        <div className='each-row  d-flex '>
                                                <span className='border-bottom border-top border-start border-end border-1 border-black w-50'>Buying Price</span>
                                            
                                                <span className='border-bottom border-top border-end border-1 border-black w-50'>{buyingPrice} Tk</span>
                                              
                                                
                                        </div>
                                        <div className='each-row  d-flex bg-warning'>
                                                <span className='border-bottom border-top border-start border-end border-1 border-black w-50'>Sold</span>
                                            
                                                <span className='border-bottom border-top border-end border-1 border-black w-50'>{sold} Tk</span>
                                              
                                                
                                        </div>
                                        <div className='each-row  d-flex '>
                                                <span className='border-bottom border-top border-start border-end border-1 border-black w-50'>Profit</span>
                                            
                                                <span className='border-bottom border-top border-end border-1 border-black w-50'>{profit} Tk</span>
                                              
                                                
                                        </div>
                                        { expenses.map((expense)=>{

                                          return(
                                            <div className='each-row  d-flex '>
                                                <span className='border-bottom border-top border-start border-end border-1 border-black w-50'>{expense.name}</span>
                                            
                                                <span className='border-bottom border-top border-end border-1 border-black w-50'>-{expense.amount} Tk</span>
                                              
                                                
                                        </div>
                                          )
                                        })

                                        }

                                          <div className='each-row  d-flex bg-info'>
                                                <span className='border-bottom border-top border-start border-end border-1 border-black w-50'>Net Profit</span>
                                            
                                                <span className='border-bottom border-top border-end border-1 border-black w-50'>{profit-cost} Tk</span>
                                              
                                                
                                          </div>
                                        
                             
                            
                   </div>

                   <div className='btn btn-info fs-4'>
          Stock balance: {total} Tk
    </div>
                   
    </div> //main div
  )
}


}
