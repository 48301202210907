import React, { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

export default function Category() {
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  const [categoryInput, setCategory] = useState({
     slug:'',
     name:'',
     description:'',

     metaKeywords:'',
     metaTitle:'',
     metaDescription:'',
     errorList:[],
  });

 const handleInput = (e) =>{
    e.persist();
    setCategory({...categoryInput,[e.target.name]:e.target.value});
 }

 const submitCategory = (e) =>{
   e.preventDefault();
  const catInput2 = {
    slug:categoryInput.slug,
    name:categoryInput.slug,
    description:categoryInput.slug,

    metaKeywords:categoryInput.slug,
    metaTitle:categoryInput.slug,
    metaDescription:categoryInput.slug,
  }
   axios.post('/api/add-category',catInput2,{
    headers: { 'Allowed-Source': allowedSource }
})
   .then(response=>{
     const res = response.data;

    if(res.status ===200){
        
        document.getElementById('theForm').reset();
       swal("Success",res.message,"success");
     
 
    }
    else if(res.status ===400){
        setCategory({...categoryInput,errorList:res.message});
    }


   });


 }


  return (
    //main div
    <div className='container-fluid px-4'> 
     <h1>Add category</h1>

<form onSubmit={submitCategory} id='theForm'>
    

 

  
    

 

  <div className="form-group mb-3">
    <label >Name</label>
    <input onChange={handleInput} value={categoryInput.slug} class="form-control"  type="text" id="slug" name='slug' />
  </div>

 



 

 

<button type='submit' className='btn btn-primary'>Submit</button>

</form>

    </div> //main div
  )
}
