import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function ProfitByMonth() {
  let history = useHistory();

  const [loading,setloading] = useState(true);

  const [accessDenied,setAccess] = useState(true);
 
 
  const [expenses,setExpenses] = useState([]);
  
  

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  
  useEffect(() => {
     
  

   axios.get('/api/get-expenses', {
    headers: { 'Allowed-Source': allowedSource }
})
.then(res=>{
    
    if(res.data.status===200){
      
       setExpenses(res.data.expenses);
       
       setAccess(false);
    }
    else{
       
       // history.push('/');
    }
    setloading(false);

})
.catch(error=>{
  
   // history.push('/admin');
}); 

    setloading(false)

    }, [history]);


    axios.interceptors.response.use(function(response){
      return response;
    },function (error){
     if(error.response.status === 403){//access denied
         swal("Forbidden",error.response.data.message,"warning");
         setAccess(true);
         history.push("/admin/dashbboard")
     }
      
    }
  
    );
   
    const handleDelete = (id)=>{
     const data = {
        id:id
     }

        axios.post('/api/delete-expense',data, {
            headers: { 'Allowed-Source': allowedSource }
        })
        .then(res=>{
            
            if(res.data.status===200){
              
               swal("Deleted","Deletion successful",'success')
               window.location.reload();
            }
            else{
               
               // history.push('/');
            }
            setloading(false);
        
        })
        .catch(error=>{
          
           // history.push('/admin');
        }); 
   


    }

    const addExpense = (e)=>{
        e.preventDefault();
        if(input.name =="" || input.amount <0)
          swal("Warning","Fill the fields correctly","warning")
        else{

            axios.post('/api/add-expense',input, {
                headers: { 'Allowed-Source': allowedSource }
            })
            .then(res=>{
                
                if(res.data.status===200){
                  
                   swal("Added","Successful",'success')
                   window.location.reload();
                }
                else{
                   
                   // history.push('/');
                }
                setloading(false);
            
            })
            .catch(error=>{
              
               // history.push('/admin');
            }); 

        }
    }
 
  
    const [input, setInput] = useState({
        name:'',
        amount:0,
    
     });

    const handleInput = (e) =>{
        e.persist();
        if(e.target.type ==="number")
        setInput({...input,[e.target.name]:parseInt(e.target.value, 10)});
        else
        setInput({...input,[e.target.name]:e.target.value});
     }
 

if(loading){
  return(
    <div>Loading..</div>
  )
}
else if(accessDenied){
  return(
<div className='text-black'>Access Denied</div>
  )

}
else{
  return (
    //main div
    <div className='text-black d-flex flex-column w-75 m-auto'> 
    
       { expenses.length >0 &&
            expenses.map((expense)=>{

                return(
                    <div className='each-row  d-flex w-100 mx-auto my-2'>
                    <span className='bg-white border-bottom border-top border-start border-end border-1 border-black w-25'>{expense.expense.name}</span>

                    <span className='bg-white border-bottom border-top border-end border-1 border-black w-25'> {expense.amount}</span>
                    <Link to={`/admin/update-expense/${expense.expense_id}`} className='btn btn-warning border-bottom border-top border-end border-1 border-black w-25'>Update</Link>
                    <button onClick={()=>handleDelete(expense.expense_id)} className='btn btn-danger border-bottom border-top border-end border-1 border-black w-25'>Delete</button>
                  
                    
                      </div>
                )
            })
       }
     {/* Form Starts*/}
<form onSubmit={addExpense} id='theForm'> 

<div class="mb-3">
  <label>Name</label>
  <input type="text" class="form-control" name='name' value={input.name} onChange={handleInput}/>
 
</div>

<div class="mb-3">
  <label>Amount</label>
  <input type="number" class="form-control" name='amount' value={input.amount} onChange={handleInput}/>
 
</div>



<button type="submit" className="btn btn-warning border-bottom border-top border-end border-1 border-black w-50 mx-auto my-4">Add New Expense</button>


</form>
{/* Form Ends*/}

   
              
    </div> //main div
  )
}


}
