import React, { useState } from "react";
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

export default function Register(props) {


  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  const history = useHistory();

  const [registerInput, setregisterInput] = useState({
    name:'',
    email:'',
    password:'',
    error_list:[],
});



const handleInput = (e)=>{
   e.persist();
   setregisterInput({...registerInput,[e.target.name]:e.target.value});
}



const registerSubmit = (e)=>{
  swal("Processing","Please Wait",'success');

    e.preventDefault();
    const data = {  
        name : registerInput.name,
        email : registerInput.email,
        password : registerInput.password,
    }


    axios.get('/sanctum/csrf-cookie', {
      headers: { 'Allowed-Source': allowedSource }
  }).then(response => {
      axios.post('/api/register',data, {
        headers: { 'Allowed-Source': allowedSource }
    }).then(res =>{
      
         if(res.data.status===200){
           
          // localStorage.setItem('auth_token',res.data.token);
          // localStorage.setItem('auth_name',res.data.username);
          // props.setloggedIn(true);
           
           swal("Success",res.data.message,'success');
           history.push('/home');
           
         }
         else{
          setregisterInput({...registerInput, error_list: res.data.message});
         }
    });
  });
   
}

 


  return (
    <>
     
     
    


    <div className="Register  my-5">
      <form className="card" onSubmit={registerSubmit}>
        <h1>Register</h1>
      <div className=" mb-3 w-75 m-auto">
          <label for="name" className="form-label">
            Name
          </label>
          <input
            type=""
            className="form-control"
            id="exampleInputName"
            name="name"
            value={registerInput.name}
            onChange={handleInput}
          />
          <h6>{registerInput.error_list.name}</h6>
        </div>
        <div className=" mb-3 w-75 m-auto">
          <label for="exampleInputEmail1" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            name="email"
            value={registerInput.email}
            onChange={handleInput}
          />
          <h6>{registerInput.error_list.email}</h6>
        </div>
        <div className=" mb-3 w-75 m-auto">
          <label for="exampleInputPassword1" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            name="password"
            value={registerInput.password}
            onChange={handleInput}
          />
           <h6>{registerInput.error_list.password}</h6>
        </div>
        
        <div className=" mb-3 w-75 m-auto form-check"></div>
        <button type="submit" className="btn btn-primary  w-50 m-auto">
          Register
        </button>
      </form>
    </div>
    </>
  );
}
