import React, { useEffect } from 'react'
import {BrowserRouter as Router,Route,Switch, BrowserRouter,Redirect} from 'react-router-dom';
import { Link } from 'react-router-dom';
 
import routes from '../../routes/routes';
import { useState } from 'react';
import menu from '../../images/menu.png';
import close from '../../images/close.png';
 

export default function MasterLayout() {

  const [Show, setShow] = useState(false);
  const [user, setUser] = useState("");

  const toggleSidebar = ()=>{
    setShow(!Show);
  }
 
  useEffect(() => {
  
      let name = localStorage.getItem("auth_name");
      if(name!==null)
        setUser(name)
  }, []);


  return (

 
    <div className='master admin-bg text-white'>

          

          <nav className="admin-sidebar-btn d-flex flex-wrap justify-content-between">
     <button onClick={()=>toggleSidebar()}  className=' admin-btn d-flex'>
     <img className='admin-sidebar-open  my-2' src={menu} alt='Open'></img>
     <h3 className='mx-3 mt-3 text-white'>Welcome,{user}</h3>
     </button>
   
     <div className='d-flex flex-wrap align-items-center justify-content-center dash-button'>
     
     <div>
        <Link className='btn btn-primary mx-2  border border-1' to={'/admin/buyCart/buy'}>Buy Cart</Link>
        <Link className='btn btn-danger mx-2  border border-1' to={'/admin/sellCart/sell'}>Sell Cart</Link>
        <Link className='btn btn-secondary mx-2  border border-1' to={'/admin/all-product/regular'}>Regular</Link>
        </div>
     </div>
    </nav>
        

    <div className={`admin-sidebar text-start d-flex flex-column  ${Show ? 'sidebar-show' : ''}`}>
           <div className='text-center w-100'>
              <button onClick={()=>toggleSidebar()} className='m-3 admin-btn text-center'>
                    <img className='admin-sidebar-close ' src={close} alt='Close'></img>
              </button>
            </div>  

          <Link to='/admin/dashboard' className='dec-none navItem' >Dashboard</Link>
                  

                 
                  
                  <h3>Product</h3>
                  <Link to='/admin/add-product' className='dec-none navItem'>Add Product</Link>
                  <Link to='/admin/view-products' className='dec-none navItem'>View Products</Link>

                  <h3>Category</h3>
                  <Link to='/admin/add-category' className='dec-none'>Add Category</Link>
                  <Link to='/admin/view-category' className='dec-none'>View Category</Link>

                  <h3>Company</h3>
                  <Link to='/admin/all-product/buy' className='dec-none navItem'>Buy</Link>
                  <Link to='/admin/all-product/sell' className='dec-none navItem'>Sell</Link>

                  <h3>Bills</h3>
                  <Link to='/admin/billDates' className='dec-none navItem'>Bills</Link>
                  <h3>Profit by date</h3>
                  <Link to='/admin/profit-dates' className='dec-none navItem'>Dates</Link>
                  <h3>Profit by month</h3>
                  <Link to='/admin/profit-months' className='dec-none navItem'>Months</Link>
                  <h3>Expenses</h3>
                  <Link to='/admin/expenses' className='dec-none navItem'>View/Add Expense</Link>
                  
                
                 
          
         
  </div>


        <div className='content '>
           
           

           <main className='w-100 text-black'>
            
            <Switch>
                { 
                routes.map((route,index)=>{


                 return(
                     route.component &&
                     <Route
                     key={index}
                     path={route.path}
                     name={route.name}
                     exact = {route.exact}
                     render={(props)=>(
                           <route.component {...props} user={user}/> 
                      )}
                     />
                 ) 

                })


                }
                <Redirect from="/admin" to="/admin/dashboard"/>

                

            </Switch>
 
            

           </main>
 

        </div>
       







    </div>
 
  )
}
