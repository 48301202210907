import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';

export default function Verify() {
   // const history = useHistory();
    const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
    const { email,token } = useParams();
    const [loading, setloading] = useState(true);
    const [response, setResponse] = useState("");


    useEffect(() => {
        const data = {
           email :email,
           token:token
        }
         axios.post('/api/verify-reg', data, {
           headers: { 'Allowed-Source': allowedSource }
       })
       .then(res=>{
           
           if(res.data.status===200){
               
            swal("Verified,Please login",res.data.message,'success');
         
         //   history.push('/');
           }
           else{
               swal("Warning",res.data.message,'warning')
                
               setResponse(res.data.message);
           }
           setloading(false);
   
       })
       .catch(error=>{
           console.log(error);
           //history.push('/');
      });  
   
   
   
       }, []);
if(loading){
    return(
        <div>
            Verifying...
        </div>
    )
}
else{
  return (
    <div>
      <h6>{response}</h6>
    </div>
  )
}
}
