import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import { useParams } from 'react-router-dom';

export default function Checkout(props) {
  let history = useHistory();
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
    const [cart, setCart] = useState([]);

    const { buyOrSell } = useParams();
  
    

    

    const [detailsInput, setDetails] = useState({
        name:"",
        address:"",
        total:0,
        paid:0,
        due:0,
        company:"yes",
        buyOrSell:buyOrSell,
    });

    const handleInput = (e)=>{
        e.persist();
        if(e.target.type ==="number")
        setDetails({...detailsInput,[e.target.name]:parseFloat(e.target.value, 10)});
        else
        setDetails({...detailsInput,[e.target.name]:e.target.value});
        
         

     }
    console.log(detailsInput);
 

    useEffect(() => {
       let cart = []
       if(buyOrSell==="buy")
         cart = JSON.parse(localStorage.getItem('buyCart'));
       else
         cart =JSON.parse(localStorage.getItem('sellCart')); 


       if(cart===null){
        cart = [];
        history.push('/home');
       }
        

        setCart(cart);
        
        let total = 0;
        if(cart!=null){
            cart.forEach(item => {
                total = total + (item.price*item.quantity);
            });
        }
        setDetails({...detailsInput,["total"]:total})


    }, [history])

   
  
    const Checkout = (e,stat)=>{
      e.preventDefault();
  
     if(cart.length===0){
      swal("Empty cart","Your cart is empty","warning");
      return;
     }
       


        
    
        
     const data={
        cart:cart,
        details:detailsInput
     }
     console.log(data);

     if(stat===1){
     axios.post('/api/sell',data, {
      headers: { 'Allowed-Source': allowedSource }
  })
  .then(res =>{
        if(res.data.status===200){
          localStorage.removeItem('buyCart');
          
          swal("Success",res.data.message,'success');
   
        }
        history.push('/admin/dashboard');
   })
   .catch(error=>{
      console.log(error);
   });
  }
  else{
    console.log(data)
    axios.post('/api/sell',data, {
      headers: { 'Allowed-Source': allowedSource }
  })
  .then(res =>{
        if(res.data.status===200){
          localStorage.removeItem('sellCart');
          
          swal("Success",res.data.message,'success');
   
        }
        history.push('/admin/dashboard');
   })
   .catch(error=>{
      console.log(error);
   });
  }

  
    }



  //calculate total
    let cartData = []
    if(buyOrSell==="buy")
        cartData = JSON.parse(localStorage.getItem('buyCart'));
    else
        cartData = JSON.parse(localStorage.getItem('sellCart'));
        

    let total = 0;
    let due = 0.00;
    if(cartData!=null){
     
      cartData.forEach(element => {
        total +=(element.quantity*element.price);
      });

    }

    due = parseFloat(total-detailsInput.paid).toFixed(2) ;
    //////

  return (
    <div>
        
         <div className="details container my-5">
      <form className="card" onSubmit={Checkout}>
        <h1>Details</h1>
     
        <div className=" mb-3 w-75 m-auto">


       
       



          <label for="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputName"
            name="name"
            value={detailsInput.name}
            onChange={handleInput}
          />

<label for="address" className="form-label">
            Address
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputName"
            name="address"
            value={detailsInput.address}
            onChange={handleInput}
          />
         
        </div>


        <div class="my-3">
                  <label>Paid</label>
                  <input type="number" class="form-control" name='paid' value={detailsInput.paid} onChange={handleInput}/>
                
                </div>

                <div class="my-3">
                  <label>Due</label>
                  <input type="number" class="form-control" name='paid' value={due}/>
               
                </div>



        
       
        <h4 >Total : {total}tk</h4>

       <div className=''>
        { buyOrSell === "buy" ?
        (
              <button onClick={(e)=>Checkout(e,1)} type="submit" className="btn btn-primary m-2 my-3">
              Buy
              </button>
        ):
        (
          <button onClick={(e)=>Checkout(e,-1)} type="submit" className="btn btn-primary m-2 my-3">
          Sell
         </button>
        )
        }
        
       

        
        </div>
      </form>
    </div>
 
    </div>
  )
}
