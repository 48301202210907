import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import logo from '../../images/logo.jpg'

export default function BillDetails() {
  
    const {id}  = useParams();

    
    let history = useHistory();
    const [loading, setloading] = useState(true);
    
    const [billDetail, setBillDetail] = useState([]);
  
    const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;

    useEffect(() => {
       
      axios.post('/api/billDetail',{id:id},{
        headers: { 'Allowed-Source': allowedSource }
    })
      .then(res=>{
          
          if(res.data.status===200){
         
              setBillDetail(res.data.billDetail);
            
          }
          setloading(false);
  
      })
      .catch(error=>{
          //console.log(error);
        //  history.push('/admin/dashboard');
     });  
  
  
  
      }, [history]);
  


  console.log(billDetail)

   function view(){
    const divElement = document.querySelector('.bill-detail-container');
    const divHeight = divElement.offsetHeight;
    const divWidth = divElement.offsetWidth;

    if(divHeight>divWidth)
      return 'p'
    return 'l'
      
   }
  const downloadPDF=()=>{
    const capture = document.querySelector(".bill-detail-container");

    html2canvas(capture).then((canvas)=>{
        const imgData = canvas.toDataURL('img/png')
        const doc = new jsPDF(view(),'mm','a4')
        const componentWidth = doc.internal.pageSize.getWidth()
        const componentHeight = doc.internal.pageSize.getHeight()
        doc.addImage(imgData,'PNG',0,0,componentWidth,componentHeight)
        doc.save(`receipt_${billDetail.name}_${billDetail.id}.pdf`)
    })
  }
  
  if(loading){
    return(
      <div>Loading..</div>
    )
  }
  else{
  return (
    <>
    <div className='bill-detail-container text-black my-3  0 mx-auto d-flex flex-column justify-content-between'>
             <div className='bill-inner d-flex flex-column text-center w-100  mx-auto'>
                   <div className='bill-top d-flex flex-column'>
                        
                            <div className='d-flex justify-content-center'>
                                <img className='bill-logo' src={logo}/>
                                <h1 className='my-1'>ALAMGIR ENTERPRISE</h1>
                            </div>

                            <span className=''>All kinds of Garments Accessories Office Stationary Spare Parts</span>
                            <span className=''>Boilar and civil items importer supplier whole seller</span>
                            <span className=''>Shop No:05,Ashulia New Market(Bakul Tower) Ground Floor ashulia Dhaka</span>
                            <span className=''>For Any Query:01878-677264,01312-771416--Email:alamgirenter2@gmail.com</span>
                            <span className=''>Vat Rat No. 171110119079,Area Code:170303</span>
                        
                       
                      
                   </div>
                   <div className='buyer-detail border border-2 border-black'>
                       <h4>Name: {billDetail.name}</h4>
                       <h4>Address: {billDetail.address}</h4>
                       <h4>Bill No: {billDetail.id}</h4>
                       <h4>Date: {billDetail.created_at.substring(0,10)}</h4>
                   </div>
                   <div className='bill-items d-flex flex-column border border-1 border-black'>

                                     <div className='bill-item d-flex border border-1 border-black'>
                                         <span className='border-end border-1 border-black w-75'>Product Name</span>
                                         <span className='border-end border-1 border-black w-25'>Quantity</span>
                                         <span className='border-end border-1 border-black w-25'>Price</span>
                                         <span className=' border-1 border-black w-25'> Total</span>
                                    </div>
                             {
                                 billDetail.deals.map((deal)=>{
                                  return(
                                    <div className='bill-item d-flex border border-1 border-black'>
                                         <span className='border-end border-1 border-black w-75'>{deal.product_name}</span>
                                         <span className='border-end border-1 border-black w-25'>{deal.quantity}</span>
                                         <span className='border-end border-1 border-black w-25'>{deal.price} Tk</span>
                                         <span className=' border-1 border-black w-25'> {deal.quantity*deal.price}Tk</span>
                                    </div>
                                  )
                                 })
                             }
                             <div className='bill-item d-flex border border-1 border-black'>
                                         <span className=' border-black w-75 '>Total</span>
                                         <span className='  border-black w-25'></span>
                                         <span className='border-end  border-black w-25'></span>
                                         <span className=' border-1 border-black w-25'>{billDetail.total}  Tk</span>
                                        
                                       
                             </div>
                             <div className='bill-item d-flex border border-1 border-black'>
                                         <span className=' border-1 border-black w-75'>Paid</span>
                                         <span className='  border-black w-25'></span>
                                         <span className='border-end  border-black w-25'></span>
                                         <span className=' border-1 border-black w-25'>{billDetail.paid}  Tk</span>
                                       
                             </div>
                             <div className='bill-item d-flex border border-1 border-black'>
                                         <span className='  w-75'>Due</span>
                                         <span className='    w-25'></span>
                                         <span className='border-end  border-black w-25'></span>
                                         <span className=' w-25'>{billDetail.total-billDetail.paid} Tk</span>
                                       
                             </div>
                   </div>
                  

               
             </div>

             <div className='signature-container d-flex justify-content-between '>
                           <div className='signature w-25 mt-5 mx-2'>
                                <div className='border border-1 border-black'></div>
                                Received By
                           </div>
                           <div className='signature w-25 mt-5 mx-2'>
                           <div className='border border-1 border-black'></div>
                                Authorised Signature
                           </div>
                   </div>
            
            
    </div>
            <div>
              <button className='btn btn-primary' onClick={()=>downloadPDF()}>Download</button>
            </div>
</>
  )
  }
}
