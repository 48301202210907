import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function Buy() {
  let history = useHistory();
  const { id } = useParams();
  const { buyOrSell } = useParams();

  const [details, setDetails] = useState({
     quantity:0,
     price:0.00,
  });

  const [product,setProduct] = useState(null);
  const [loading, setloading] = useState(true);
  const [errors, setErrors] = useState([]);
  

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  
  useEffect(() => {
     
    const data = {
      id :id
   }

   axios.post('/api/get-product-by-id', data, {
    headers: { 'Allowed-Source': allowedSource }
})
.then(res=>{
    
    if(res.data.status===200){
        setProduct(res.data.product)
    }
    else{
        setErrors(res.data.messages);
       // history.push('/');
    }
    setloading(false);

})
.catch(error=>{
  
   // history.push('/admin');
}); 

    setloading(false)

    }, [history]);


    const handleInput = (e) =>{
  
      e.persist();
      if(e.target.type ==="number")
      setDetails({...details,[e.target.name]:parseFloat(e.target.value, 10,2)});
      else
      setDetails({...details,[e.target.name]:e.target.value});
   }

   const handleSelectChange = (event) => {
    setDetails({...details,[event.target.name]:event.target.value});
  };


  function alreadyAdded(id){
    
    let cartData = []
    if(buyOrSell==="buy")
    cartData = JSON.parse(localStorage.getItem("buyCart"));
  else
cartData = JSON.parse(localStorage.getItem("sellCart"));

  
    if(cartData===null){
      return false;
    }
   
    let flag =0;

    cartData?.forEach(item => {
   
        if(item.product.id===id){
            flag = 1;
        }
    });
    
    if(flag)
       return true;

    return false;
 }

 const submitProduct = (e) =>{
  e.preventDefault();

   if(details.price<=0 || details.quantity<=0){
    
    swal("Warning","Fill all the fields","warning")
    return;
   }
   if(buyOrSell==="sell" || buyOrSell==='regular'){
    if(details.quantity>product.stock){
      swal("Warning","Not available","warning")
      return;
    }
    
   }
  
    if(buyOrSell==='regular'){

/////////////////////////
      const data = {
        id :id,
        quantity:details.quantity,
        price:details.price,
        company:"no",
        name:"customer",
     }
  
     axios.post('/api/sell-all', data, {
      headers: { 'Allowed-Source': allowedSource }
  })
  .then(res=>{
      
      if(res.data.status===200){
          swal("successful","Sold","success")
          history.go(-1)
      }
      else{
        
         // history.push('/');
      }
   
  
  })
  .catch(error=>{
    
     // history.push('/admin');
  }); 

/////////////////////////////
 return;
        
    }
   else if(buyOrSell==="buy"){
   let cartData = JSON.parse(localStorage.getItem("buyCart"));
   if(cartData===null)
     cartData = [];


   if(alreadyAdded(product.id)){
    swal("Warning","Already Added","warning")
   } 
   else{
    const data={
      product:product,
      quantity:details.quantity,
      price:details.price
    }

    cartData.push(data);
    localStorage.setItem("buyCart",JSON.stringify(cartData));
    history.push("/admin/all-product/buy")

   }
  }
  else{
     
      let cartData = JSON.parse(localStorage.getItem("sellCart"));
      if(cartData===null)
        cartData = [];
   
   
      if(alreadyAdded(product.id)){
       swal("Warning","Already Added","warning")
      } 
      else{
       const data={
         product:product,
         quantity:details.quantity,
         price:details.price
       }
   
       cartData.push(data);
       localStorage.setItem("sellCart",JSON.stringify(cartData));
       history.push("/admin/all-product/sell")
   
      }
     
  }
  

 }



if(loading){
  return(
    <div>Loading..</div>
  )
}
else{
  return (
    //main div
    <div className='container-fluid px-4 text-black'> 
    


  {/* Form Starts*/}
<form onSubmit={submitProduct} id='' > 
 {product === null ?
 (<div>
  <h3>Product</h3>
  <Link className='btn btn-primary' to={"/admin/all-product"}>Select Product</Link>

 </div>
 ):
 (
    <div className='text-black'>
        
         <div className=''>
        
         <h5>Name: {product.name}</h5>
         <h3>Stock: {product.stock}</h3>
         <h3>Price: {product.price} Tk</h3>
         </div>
         
    </div>
 )
 }
 

 <div className='w-75 mx-auto'>
                <div class="mb-3 ">
                  <label>Price</label>
                  
                  <input type="number" class="form-control" name='price' value={details.price} onChange={handleInput}/>
                </div>
                
                <div class="mb-3">
                  <label>Quantity</label>
                  <input type="number" class="form-control" name='quantity' value={details.quantity} onChange={handleInput}/>
                
                </div>

{buyOrSell=="buy" ?
(
  <button onClick={(e)=>submitProduct(e,1)} type="submit" class="btn btn-primary mx-2">Buy</button>
):
(
  <button onClick={(e)=>submitProduct(e,-1)} type="submit" class="btn btn-primary mx-2">Sell</button>
)

}
                
               
           </div>     


</form>
 {/* Form Ends*/}



    </div> //main div
  )
}


}
