import AddProduct from "../components/admin/AddProduct";

import Dashboard from "../components/admin/Dashboard";



import Checkout from "../components/admin/Checkout";

import UpdateProduct from "../components/admin/UpdateProduct";

import ViewProducts from "../components/admin/ViewProducts";


import Cart from "../components/admin/Cart";
import Buy from "../components/admin/Buy";
import SelectProduct from "../components/admin/SelectProduct";
import SellCart from "../components/admin/SellCart";
import Bill from "../components/admin/Bill";
import BillDetails from "../components/admin/BillDetails";
import ProfitDates from "../components/admin/ProfitDates";
import ProfitByDate from "../components/admin/ProfitByDate";
import ProfitMonths from "../components/admin/ProfitMonths";
import ProfitByMonth from "../components/admin/ProfitByMonth";
import Expense from "../components/admin/Expense";
import UpdateExpense from "../components/admin/UpdateExpense";
import ViewCategory from "../components/admin/ViewCategory";
import Category from "../components/admin/Category";
import UpdateCat from "../components/admin/UpdateCat";

const routes = [
 {path:'/admin' ,exact:true,name:"Admin",component:Dashboard},
 {path:'/admin/dashboard' ,exact:true,name:"Dashboard",component:Dashboard},

 {path:'/admin/add-product' ,exact:true,name:"AddProduct",component:AddProduct},

 {path:'/admin/view-products' ,exact:true,name:"ViewProducts",component:ViewProducts},

 {path:'/admin/update/:id' ,exact:true,name:"UpdateProduct",component:UpdateProduct},


 {path:'/admin/product/:id/:buyOrSell' ,exact:true,name:"Buy",component:Buy},
 {path:'/admin/all-product/:buyOrSell' ,exact:true,name:"SelectProduct",component:SelectProduct},
 {path:'/admin/buyCart/:buyOrSell' ,exact:true,name:"Cart",component:Cart},
 {path:'/admin/sellCart/:buyOrSell' ,exact:true,name:"Cart",component:SellCart},
 {path:'/admin/checkout/:buyOrSell' ,exact:true,name:"Cart",component:Checkout},
 {path:'/admin/billDates' ,exact:true,name:"bill",component:Bill},
 {path:`/admin/billDetails/:id` ,exact:true,name:"bill",component:BillDetails},
 {path:'/admin/profit-dates' ,exact:true,name:"bill",component:ProfitDates},
 {path:'/admin/profit-months' ,exact:true,name:"bill",component:ProfitMonths},
 {path:"/admin/profit-by-date/:date" ,exact:true,name:"bill",component:ProfitByDate},
 {path:"/admin/profit-by-month/:date" ,exact:true,name:"bill",component:ProfitByMonth},
 {path:"/admin/expenses" ,exact:true,name:"bill",component:Expense},
 {path:"/admin/update-expense/:id" ,exact:true,name:"bill",component:UpdateExpense},
 {path:'/admin/view-category' ,exact:true,name:"ViewCategory",component:ViewCategory},
 {path:'/admin/update-cat/:id' ,exact:true,name:"UpdateCat",component:UpdateCat},
 {path:'/admin/add-category' ,exact:true,name:"Category",component:Category}
]


export default routes;