import React from 'react'
import close from '../../images/close.png';

export default function Sidebar(props) {
  return (
    <div className={`sidebar d-flex flex-column  ${props.Show ? 'sidebar-show' : ''}`}>
       <button onClick={()=>props.toggleSidebar()} className='sidebar-icon-container m-3'><img className='sidebar-icon' src={close} alt='Close'></img></button>
    
     

  </div>
  )
}
