import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';

export default function Dashboard(props) {
   
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
 //console.log(allowedSource);
  let history = useHistory();
    
  const [loading, setloading] = useState(false);

 



if(loading){
  return(
       <div>
          Loading Orders..
       </div>
  )
 } 
  
else{
  return (


    <div>        
              <div className='Finance d-flex flex-wrap justify-content-around '>  
                    

              </div> 
    </div>
  )
}
}
