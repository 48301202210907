import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function ProfitDates() {
  let history = useHistory();
  const { id } = useParams();
  const { buyOrSell } = useParams();

 
  const [accessDenied,setAccess] = useState(true);
 
  const [sellDates,setSellDates] = useState([]);
  const [loading,setloading] = useState(true);
 
  

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  
  useEffect(() => {
     
   

   axios.get('/api/sellDates', {
    headers: { 'Allowed-Source': allowedSource }
})
.then(res=>{
    
    if(res.data.status===200){
        setSellDates(res.data.sellDates)
        setAccess(false)
    }
    else{
       
       // history.push('/');
    }
    setloading(false);

})
.catch(error=>{
  
   // history.push('/admin');
}); 

    setloading(false)

    }, [history]);


    axios.interceptors.response.use(function(response){
      return response;
    },function (error){
     if(error.response.status === 403){//access denied
         swal("Forbidden",error.response.data.message,"warning");
         setloading(false);
         history.push("/admin")
     }
      
    }
  
    );


if(loading){
  return(
    <div>Loading..</div>
  )
}
else if(accessDenied){
  return(
    <div className='text-black'>
      Access Denied
    </div>
  )
}
else{
  return (
    //main div
    <div className='container-fluid px-4 d-flex flex-column my-3'> 
          {
            sellDates.map((date)=>{
                return(
                    <>
                      <Link to={`/admin/profit-by-date/${date}`} className='fs-4 btn btn-warning date-btn m-1 mx-auto'>{date}</Link>
                    </>
                )
            })
          }
    </div> //main div
  )
}


}
